import React from "react";
import "./footer.css";
import { withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = (props) => {
  const handleMove = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer">
      {props.location.pathname !== "/contact" && (
        <div className="top" id="top-icon" onClick={handleMove}>
          <FontAwesomeIcon className="up-icon" icon="angle-double-up" />
        </div>
      )}
      <div className="footer-content">
        &copy; 2020 Amit Rathore
        <div className="site-map">
          <div className="sitemap-links">
            <Link to="/">Home</Link>
          </div>
          <div className="sitemap-links">
            <Link to="/about">About</Link>
          </div>
          <div className="sitemap-links">
            <Link to="/portfolio">Portfolio</Link>
          </div>
          <div className="sitemap-links">
            <Link to="/contact">Contact</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Footer);
