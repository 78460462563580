import React from "react";
import CardList from "../../components/card-list/card-list";
import "./portfolio.styles.css";
import "../../animation/animation.css";

const Portfolio = () => {
  return (
    <div className="container portfolio">
      <h1>My Portfolio</h1>
      <CardList />
    </div>
  );
};

export default Portfolio;
