import React, { useEffect } from "react";
import "./modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Modal = ({
  isOpen,
  id,
  title,
  subheading,
  desc,
  iconNames,
  imgUrl,
  linkUrl,
}) => {
  useEffect(() => {
    if (isOpen) {
      var modal = document.getElementById(`${id}`);
      modal.style.display = "block";
      var span = document.getElementsByClassName("close")[0];
      span.onclick = function () {
        modal.style.display = "none";
      };
      window.onclick = function (event) {
        if (event.target === modal) {
          modal.style.display = "none";
        }
      };
    }
  }, [id, isOpen]);

  return (
    <div id={`${id}`} className="modal">
      <div className="modal-content">
        <div className="tech-icon">
          {iconNames.map((icon, idx) => (
            <FontAwesomeIcon
              key={idx}
              className="icons"
              icon={["fab", `${icon}`]}
            />
          ))}
          {/* <FontAwesomeIcon icon={["fab", `${iconName}`]} /> */}
        </div>
        <span id={`${id}-close`} className="close">
          &times;
        </span>
        <div className="modal-body">
          <div className="heading">
            <div className="heading-txt">
              <h1>{title}</h1>
              <h3>{subheading}</h3>
              <div
                className="modal-desc"
                dangerouslySetInnerHTML={{ __html: desc }}
              ></div>
              {linkUrl && (
                <div className="link">
                  <span>VIEW WEBSITE</span>{" "}
                  <a
                    className="modal-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkUrl}
                  >
                    <FontAwesomeIcon icon="external-link-alt" />
                  </a>
                </div>
              )}
            </div>
            <div className="heading-img">
              <img src={imgUrl} alt="portfolio projects" />
            </div>
          </div>

          <div className="add-content"></div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
