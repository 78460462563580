import React, { useState, useEffect } from 'react';
import './header.css';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../AR_Brand_Latest.svg';

const Header = () => {
  const [menuDisplay, setMenuDisplay] = useState(false);
  useEffect(() => {
    const divBar = document.getElementById('open-menu');
    if (window.screen.width <= 760 || window.innerWidth <= 760) {
      if (menuDisplay) {
        divBar.style.display = 'flex';
      } else {
        divBar.style.display = 'none';
      }
    }

    window.addEventListener('click', (event) => {
      if (event.target === divBar) {
        divBar.style.display = 'none';
        setMenuDisplay(!menuDisplay);
      }
    });

    return () => window.removeEventListener('click', (event) => {});
  }, [menuDisplay]);

  const handleBar = () => {
    setMenuDisplay(!menuDisplay);
  };
  return (
    <header className="App-header">
      <div className="img-holder">
        <Link to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </div>
      <div className="menu-items">
        <div id="menu-items__bar" className="menu-bar" onClick={handleBar}>
          <FontAwesomeIcon icon={['fa', 'bars']} />
        </div>
        <div id="open-menu" className="menu">
          <div className="menu-links">
            <NavLink activeClassName="active" exact to="/" onClick={handleBar}>
              Home
            </NavLink>
          </div>
          <div className="menu-links">
            <NavLink activeClassName="active" to="/about" onClick={handleBar}>
              About
            </NavLink>
          </div>
          <div className="menu-links">
            <NavLink
              activeClassName="active"
              to="/portfolio"
              onClick={handleBar}
            >
              Portfolio
            </NavLink>
          </div>
          <div className="menu-links">
            <NavLink activeClassName="active" to="/contact" onClick={handleBar}>
              Contact
            </NavLink>
          </div>
          <div className="social-links">
            <a
              href="https://twitter.com/AmitRathoreDev"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="icons" icon={['fab', 'twitter']} />
            </a>
            <a
              href="https://www.linkedin.com/in/amit-rathore-webdev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="icons"
                icon={['fab', 'linkedin-in']}
              />
            </a>
            <a
              href="https://www.instagram.com/amit.rathore.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="icons" icon={['fab', 'instagram']} />
            </a>
            {/* <Link to="https://twitter.com/AmitRathoreDev">
              <FontAwesomeIcon className="icons" icon={["fab", "facebook-f"]} />
            </Link> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
