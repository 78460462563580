import React, { useEffect } from "react";
import "./bar.css";

const SkillBar = ({ name, percent }) => {
  useEffect(() => {
    window.addEventListener("scroll", animationOnScroll);
    return () => window.removeEventListener("scroll", animationOnScroll);
  }, []);

  function animationOnScroll() {
    const distanceY =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    const skillDiv = document.getElementsByClassName("fill");

    for (const el of skillDiv) {
      if (distanceY > 700) el.style.display = "block";
    }
  }

  return (
    <>
      <div className="bar-length ani">
        <div className="bar flex">
          <div
            id={name}
            className="bar fill"
            style={{ width: `${percent}%` }}
          />
          <div className="name flex">{name}</div>
          <div className="percent">{percent}%</div>
        </div>
      </div>
    </>
  );
};

export default SkillBar;
