import React, { useState, useEffect } from "react";
import "./card-list.css";
import Card from "../card/card";
import Modal from "../modal/modal";
import { dbClient } from "../../firebase/firebase.utils";
import Loader from "../loader/loader";

const CardList = ({ noProjects }) => {
  const [portList, setportList] = useState({
    cardList: {
      title: "",
      project: "",
      description: "",
      imgUrl: "",
      imgCover: "",
      linkUrl: "",
      languages: [],
    },
    id: "",
    openModal: false,
  });
  const [loadData, setLoadData] = useState({ myData: [] });
  const [loading, setLoading] = useState({ isLoading: true });
  const handleModal = (item, index) => {
    setportList({ cardList: item, id: index, openModal: true });
  };
  useEffect(() => {
    const apiData = [];
    const unsubscribe = () => {
      dbClient
        .collection("portfolios")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const {
              title,
              project,
              description,
              languages,
              imgUrl,
              imageCover,
              linkUrl,
            } = doc.data();
            apiData.push({
              title,
              project,
              description,
              imgUrl,
              imgCover: imageCover,
              linkUrl,
              languages,
            });
          });
          setLoadData({ myData: apiData });
        })
        .catch((error) => console.log("error getting data", error));
      setLoading({ isLoading: false });
    };
    unsubscribe();

    if (portList.openModal) {
      setportList({ ...portList, openModal: false });
    }
  }, [portList, portList.openModal]);

  return (
    <>
      {loading.isLoading && (
        <div className="loader">
          <Loader />
        </div>
      )}
      <div className="card-list">
        {loadData.myData.map((item, idx) => {
          if (idx > noProjects - 1) {
            // eslint-disable-next-line array-callback-return
            return;
          }
          return (
            <Card
              key={item.title}
              id={idx}
              title={item.title.toUpperCase()}
              subheading={item.project}
              imgUrl={item.imgCover}
              iconNames={item.languages}
              handleModal={() => handleModal(item, idx)}
            />
          );
        })}
      </div>
      <Modal
        id={portList.id}
        title={portList.cardList.title}
        subheading={portList.cardList.project}
        desc={portList.cardList.description}
        iconNames={portList.cardList.languages}
        isOpen={portList.openModal}
        imgUrl={portList.cardList.imgUrl}
        linkUrl={portList.cardList.linkUrl}
      />
    </>
  );
};

export default CardList;

// dbClient
// .collection("portfolios")
// .get()
// .then((querySnapshot) => {
//   querySnapshot.forEach((doc) => {
//     const {
//       title,
//       project,
//       description,
//       languages,
//       imgUrl,
//       imageCover,
//       linkUrl,
//     } = doc.data();

//     apiData.push({
//       title,
//       project,
//       description,
//       imgUrl,
//       imgCover: imageCover,
//       linkUrl,
//       languages,
//     });
//   });
// });
