import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faExternalLinkAlt,
  faAngleDoubleUp,
  faBars,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import './App.css';
import Home from './pages/home/home';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import AdminPage from './pages/admin/admin';
import Portfolio from './pages/portfolio/portfolio';
import ResumeLink from './components/resume/resume';
import NotFound from './pages/notfound/notfound';

library.add(
  fab,
  faEnvelope,
  faExternalLinkAlt,
  faAngleDoubleUp,
  faBars,
  faSpinner
);

function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-body">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/contact" component={Contact} />
          <Route path="/about" component={About} />
          <Route path="/resume" component={ResumeLink} />
          <Route path="/404" component={NotFound} />
          <Route
            render={() =>
              window.location.pathname.includes('/admin-ar090') ? (
                <AdminPage />
              ) : (
                <Redirect to="/404" />
              )
            }
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
