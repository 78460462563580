import React, { useEffect } from 'react';
import './about.css';
import { Link } from 'react-router-dom';
import photo from '../../assets/profile_pic_circle2.png';
import pieChart from '../../assets/PieChart2.png';
import SkillBar from '../../components/UI/bar';

const About = () => {
  useEffect(() => {
    window.addEventListener('scroll', animationOnScroll);
    return () => window.removeEventListener('scroll', animationOnScroll);
  }, []);
  function animationOnScroll() {
    const distanceY =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    const pieDiv = document.getElementsByClassName('pie-chart');

    for (const el of pieDiv) {
      if (distanceY > 150) el.style.display = 'block';
    }
  }
  return (
    <>
      <div className="about-page container">
        <div className="about-info">
          <h1>about me</h1>
          <p className="intro">
            I'm full stack developer based in sunny Delhi NCR, India
          </p>
          <p>
            I enjoy turning complex problems into simple, beautiful and
            intuitive designs. I spend my days designing and developing
            javascript websites. Constantly learning and apply new or updated
            technologies based on performance and usability.
          </p>
          <p>
            I'm skilled at many areas of design and development. Some of my
            skills incude:
          </p>
          <ul>
            <li>HTML5 / CSS3 / SASS</li>
            <li>Javascript / React / Redux / Node.js Express</li>
            <li>Responsive design - Bootstrap / MaterialUI / Flexbox</li>
            <li>GraphQL / SQL</li>
            <li>MangoDB / PostgreSQL / MySql / MSSqlServer</li>
            <li>Graphic design using Adobe Photoshop and Illustrator</li>
          </ul>
        </div>
        <div className="profile-info">
          <div className="profile-photo">
            <img src={photo} alt="profile pics" />
          </div>
          <div className="resume-wrapper">
            <Link to="/resume">
              <button className="btn-resume">View Resume</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="coderDesigner container">
        <div className="pie-chart-content">
          <p className="pie-title">Part coder</p>
          <p>Front-end development</p>
          <p>HTML / CSS</p>
          <p>JavaScript</p>
          <p>ReactJS</p>
          <p>Back-end management</p>
        </div>
        <div className="pie-chart">
          <img src={pieChart} alt="Coder Designer PieChart" />
        </div>
        <div className="pie-chart-content">
          <p className="pie-title">Part designer</p>
          <p>UX Design</p>
          <p>UI Design</p>
          <p>Logo Design</p>
          <p>Graphic Design Learner</p>
        </div>
      </div>
      <div id="skill_set" className="skills container">
        <h1>Skills</h1>
        <p>skills in percentage range</p>
        <SkillBar name="JavaScript" percent="80" />
        <SkillBar name="React" percent="80" />
        <SkillBar name="Redux" percent="85" />
        <SkillBar name="Nodejs" percent="75" />
        <SkillBar name="HTML" percent="90" />
        <SkillBar name="CSS" percent="85" />
        <SkillBar name="Sql" percent="75" />
        <SkillBar name="Photoshop" percent="75" />
        <SkillBar name="Illustrator" percent="80" />
      </div>
    </>
  );
};

export default About;
