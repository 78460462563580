import React from "react";
import "./card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = ({
  handleModal,
  id,
  title,
  imgUrl,
  urlLink,
  subheading,
  iconNames,
  ...rest
}) => {
  //const imageCover = require(`${imgUrl}`);
  return (
    <div className="card" id={`${id}-card`} onClick={handleModal}>
      {/* <a href={urlLink}> */}
      <div className="img-card-container">
        <div className="card-img">
          <img className="img-cover" src={imgUrl} alt="project" />
        </div>
      </div>

      <div className="card-description">
        <div>
          <h4>{title}</h4>
          <p>{subheading}</p>
        </div>
        <div className="tech-icon">
          {iconNames.map((icon, idx) => (
            <FontAwesomeIcon
              key={idx}
              className="icons"
              icon={["fab", `${icon}`]}
            />
          ))}
          {/* <FontAwesomeIcon icon={["fab", `${iconNames}`]} /> */}
        </div>
      </div>
      {/* </a> */}
    </div>
  );
};

export default Card;
