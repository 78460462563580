import React, { Component } from "react";
import { dbClient } from "../../firebase/firebase.utils";
import {
  HtmlEditor,
  Image,
  Inject,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import "./admin.style.css";
import "@syncfusion/ej2-base/styles/material.css";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

// import firestore from "firestore";

class AdminPage extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      htmlValue: "",
      docName: "",
      title: "",
      project: "",
      linkUrl: "",
      coverUrl: "",
      imgUrl: "",
      image: "",
      tags: ["html5"],
    };

    this.toolbarSettings = {
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "|",
        "Formats",
        "Alignments",
        "OrderedList",
        "UnorderedList",
        "Outdent",
        "Indent",
        "|",
        "CreateLink",
        "Image",
        "|",
        "ClearFormat",
        "Print",
        "SourceCode",
        "FullScreen",
        "|",
        "Undo",
        "Redo",
      ],
    };
  }
  componentDidMount() {
    const option = {
      rules: {
        defaultRTE: {
          maxLength: [508, "Maximum 500 character only"],
          minLength: [15, "Need atleast 8 character length"],
          required: true,
        },
      },
    };
    this.formObject = new FormValidator("#myForm", option);
    document.getElementById("validateSubmit").addEventListener("click", (e) => {
      const form = document.forms.myForm;
      const formData = new FormData(form);
      const docValue = formData.get("docName");
      const titleValue = formData.get("defaultTitle");
      const rteValue = formData.get("defaultRTE");
      const projectValue = formData.get("defaultProject");
      const langValue = this.state.tags;
      const imgValue = formData.get("defaultImgUrl");
      const coverValue = formData.get("defaultCoverUrl");
      const linkValue = formData.get("defaultLinkUrl");
      this.setState({ htmlValue: rteValue });
      //Use this value to the database.
      dbClient
        .collection("portfolios")
        .doc(docValue)
        .set({
          description: rteValue,
          project: projectValue,
          title: titleValue,
          languages: langValue,
          imgUrl: imgValue,
          imageCover: coverValue,
          linkUrl: linkValue,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });

      e.preventDefault();
    });
  }

  removeTag = (i) => {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.setState({ tags: newTags });
  };

  handleTags = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({ tags: [...this.state.tags, val] });
      this.tagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  };

  changeHandle = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const {
      tags,
      docName,
      title,
      project,
      imgUrl,
      coverUrl,
      linkUrl,
    } = this.state;
    return (
      <div className="container">
        <h1>ADMIN PAGE</h1>
        <h2>Text Editor</h2>
        <form id="myForm" className="form-vertical">
          <div className="form-group">
            <TextBoxComponent
              id="docName"
              value={docName}
              onChange={this.changeHandle}
              placeholder="Document Name"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <TextBoxComponent
              id="defaultProject"
              value={project}
              onChange={this.changeHandle}
              placeholder="Project type"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <TextBoxComponent
              id="defaultTitle"
              value={title}
              onChange={this.changeHandle}
              placeholder="Title"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <TextBoxComponent
              id="defaultImgUrl"
              value={imgUrl}
              onChange={this.changeHandle}
              placeholder="Image URL"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <TextBoxComponent
              id="defaultCoverUrl"
              value={coverUrl}
              onChange={this.changeHandle}
              placeholder="Image Cover URL"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <TextBoxComponent
              id="defaultLinkUrl"
              value={linkUrl}
              onChange={this.changeHandle}
              placeholder="Link URL"
              cssClass="e-outline"
              floatLabelType="Auto"
            />
          </div>
          <div className="form-group">
            <div className="form-box">
              <input
                type="text"
                className="tagsInput"
                onKeyDown={this.handleTags}
                ref={(c) => {
                  this.tagInput = c;
                }}
              />
              <label className="tag-label">Tags</label>
            </div>
            <div className="tags-list">
              {tags.map((tag, i) => (
                // eslint-disable-next-line no-unused-expressions
                <div className="tags__tag" key={tag}>
                  {tag}
                  <button
                    type="button"
                    className="tags_btn"
                    onClick={() => this.removeTag(i)}
                  >
                    +
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <RichTextEditorComponent
              id="defaultRTE"
              name="defaultRTE"
              className="form-control"
              height={450}
              value={""}
              toolbarSettings={this.toolbarSettings}
            >
              <Inject services={[Toolbar, HtmlEditor, Image, QuickToolbar]} />
            </RichTextEditorComponent>
          </div>
          <div className="form-btn-section">
            <button
              id="validateSubmit"
              className="sample-btn e-control e-btn"
              type="submit"
              data-ripple="true"
            >
              Submit
            </button>
            <button
              id="reset-btn"
              className="sample-btn e-control e-btn"
              type="reset"
              data-ripple="true"
            >
              Reset
            </button>
          </div>
        </form>

        <div className="space">
          <h6>Display HTML Content</h6>
          <div className="hml-box">{this.state.htmlValue}</div>
        </div>
      </div>
    );
  }
}

export default AdminPage;
