import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import CardList from '../../components/card-list/card-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../animation/animation.css';

const Home = () => {
  return (
    <div className="home-page">
      <div className="hero">
        <div className="hero-overlay">
          <div className="container">
            <div className="hero-content">
              <div className="profile-name">
                Hello, I'm <span>Amit Rathore</span>
              </div>
              <div className="profile-career">
                <h1>DESIGNER / DEVELOPER</h1>
              </div>
              <div>
                <Link className="hero-btn" to="/contact">
                  <FontAwesomeIcon icon="envelope" /> Contact Me
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-box container">
        <div className="feature-header">
          <h3>SOME OF MY WORK</h3>
        </div>
        <CardList noProjects={3} />
      </div>
    </div>
  );
};

export default Home;
