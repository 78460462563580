import React from "react";
import "./loader.styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loader = () => {
  return (
    <div className="icon">
      <FontAwesomeIcon icon={["fa", "spinner"]} style={{ fontSize: "52px" }} />
    </div>
  );
};

export default Loader;
