import React, { useEffect } from "react";
import "./popup.css";
import Loader from "../loader/loader";

const Popup = ({ isOpen, status, hide }) => {
  useEffect(() => {
    if (isOpen) {
      var popup = document.getElementById("popup");
      popup.style.display = "block";
      var span = document.getElementsByClassName("close")[0];
      span.onclick = function () {
        hide();
        popup.style.display = "none";
      };
      window.onclick = function (event) {
        if (event.target === popup) {
          popup.style.display = "none";
          hide();
        }
      };
    }
  }, [hide, isOpen]);

  const handleConfirm = (e) => {
    var popup = document.getElementById("popup");
    popup.style.display = "none";
    hide();
  };
  return (
    <div id="popup" className="popup-modal">
      <div className="popup-modal-content">
        <div className="popup-title">Confirmation</div>
        <span id="modal-close" className="close">
          &times;
        </span>
        <div
          className="popup-modal-body"
          style={{ color: status === "successfull" ? "#2C7972" : "red" }}
        >
          {status === "successfull" && `Email sent ${status}y!`}
          {status === "failed" && <span>Please try again.</span>}
          {status === "" && (
            <>
              <Loader />
              <span>sending email ...</span>
            </>
          )}
          {status !== "" && (
            <div className="popup-btn">
              <button className="btn" onClick={handleConfirm}>
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
