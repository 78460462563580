import React, { useState } from 'react';
import './contact.css';
import '../../animation/animation.css';
import Popup from '../../components/popup/popup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { useParams } from "react-router-dom";

const Contact = () => {
  const [formData, setFormData] = useState({});
  const [status, setStatus] = useState('');
  const [isOpen, setOpen] = useState(false);

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  const sendEmail = () => {
    // const headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // };
    //console.log(formData);
    // Axios.post(
    //   "https://us-central1-myprofile-72ece.cloudfunctions.net/app/api/contacts",
    //   { headers: headers },
    //   JSON.stringify(formData)
    // )
    setOpen(true);
    let checkStatus = '';
    fetch(
      'https://us-central1-myprofile-72ece.cloudfunctions.net/app/api/contacts',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(formData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.isEmailSend) {
          checkStatus = 'successfull';
        }
        if (res.error) {
          checkStatus = 'failed';
        }
        setStatus(checkStatus);
        return res;
      })
      .catch((error) => {
        console.log(error);
        checkStatus = 'failed';
      });
  };

  const handleClose = () => {
    setOpen(false);
    setStatus('');
  };
  return (
    <div className="contact container">
      <h1>contact</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ''}
          required
        ></input>
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ''}
          required
        ></input>
        <textarea
          type="text"
          name="message"
          placeholder="Your message"
          onChange={updateInput}
          value={formData.message || ''}
          required
        ></textarea>
        <button type="submit" className="form-btn">
          <FontAwesomeIcon
            className="send-icon"
            icon={['fab', 'telegram-plane']}
          />{' '}
          Send Message
        </button>
      </form>
      <Popup
        id="pop-modal"
        isOpen={isOpen}
        status={status}
        hide={handleClose}
      />
    </div>
  );
};

export default Contact;
