import React from "react";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { useParams } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={{ height: "450px" }}>
      <h1>Error : 404</h1>
      <h3>Opps! page not found</h3>
    </div>
  );
};

export default NotFound;
